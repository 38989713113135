import { FC } from 'react'

const NotFoundPage: FC = () => {
  return (
    <main>
      <title>Taková stránka neexistuje</title>
    </main>
  )
}

export default NotFoundPage
